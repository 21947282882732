<template>
    <div class="page flex-col">
        <header>
            <van-nav-bar
                    title="我的地址"
                    left-text=""
                    left-arrow
                    @click-left="onClickLeft"
            />
        </header>
        <div class="box_1 flex-row">
            <div class="group_1 flex-col">
                <div v-for="(user, index) in constants" :key="index">
                    <div class="box_4 flex-col"></div>
                    <div class="box_5 flex-row">
                        <div class="text-wrapper_2">
                            <span class="text_6">{{user.name}}</span>
                            <span class="text_7">&nbsp;</span>
                            <span class="text_8">{{user.phone}}</span>
                        </div>
                        <!--<div class="text-wrapper_3 flex-col" >
                            <span class="text_9" >默认</span>
                        </div>-->
                        <div style="text-align: right;">
                            <img class="label_3" referrerpolicy="no-referrer" @click="updateAddress(user.addressId)"
                                 src="./assets/img/c943441cbe6d896e085f6f0709737c21.png" />
                            <img class="label_4" referrerpolicy="no-referrer" @click="delAddress(user.addressId)"
                                 src="./assets/img/23a0a234a11e9bbe2388f18837fe804c.png" />
                        </div>

                    </div>
                    <span class="text_10">地址：{{user.address}}</span>
                    <span class="text_10_1" v-if="user.show">默认地址</span>
                    <div class="box_6 flex-col"></div>
                </div>
                <div v-if="isAddress">
                    <div class="box_4 flex-col"></div>
                    <div class="box_51 flex-row">
                        <div class="text-wrapper_2">
                            <span class="text_6">您还没收获地址，请添加！</span>
                        </div>
                    </div>
                </div>

                <div class="text-wrapper_5 flex-col" @click="addAddress"><span class="text_15">新增</span></div>
            </div>

        </div>
    </div>
</template>
<script>

    import {queryOrderAddressByMemberId,delAddress} from "@/api/user";

    export default {
        data() {
            return {
                memberId: '',
                constants: [{
                    name:'',
                    phone:'',
                    address:'',
                    show:false
                }],
                isAddress:true
            };
        },
        mounted() {
            const user = localStorage.getItem('userInfoDs')
            if(!user){
                this.$router.push('/login')
                return false
            }
            this.memberId = JSON.parse(user).memberId
            queryOrderAddressByMemberId(this.memberId).then(response => {
                if (response.code == 200) {
                    if(response.data){
                        this.isAddress = false
                        this.constants = response.data
                        this.constants.forEach((item,i) => {
                            if(item.isDefault == 0){
                                this.constants[i].show = true
                            }
                        })
                    }

                }
            })
        },
        methods: {
            addAddress() {
                this.$router.push('/addNewAddress')
            },
            updateAddress(addressId) {
                this.$router.push({ name: 'addNewAddress', params: { addressId: addressId }});
            },
            delAddress(addressId) {
                delAddress(addressId).then(response => {
                    this.$model({
                        show: true,
                        title: "提示",
                        content: response.msg,
                        confirmButton: false,
                        cancelButton: true
                    });
                    this.$router.go(0);
                })
            },
            onClickLeft() {
                this.$router.push('/wode')
            },
        }
    };
</script>
<style scoped lang="css" src="./assets/index.rem.css"/>